<template>
    <div class="Document">
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>
<script>
export default {
    name: 'Document',
    data() {
        return {

        }
    },
    created() {
        this.toPage()
    },
    methods: {
        toPage() {
            if (this.$route.path == '/document' || this.$route.path == '/document/') {
                this.$router.replace('/document/search')
            }
        }
    },
    watch: {
        $route() {
            this.toPage()
        }
    },
    components: {

    }
}
</script>
<style lang="less" scoped>
</style>
